<template>
  <a-card :bordered="false">
    <div>
      <a-row>
        <a-col :span="$mq === 'mobile' ? 24 : 10">
          <a-form :label-col="{ span: $mq === 'mobile' ? 10 : 4 }" :wrapper-col="{ span: $mq === 'mobile' ? 24 : 12 }">
            <a-form-item label="Tra cứu mã">
              <a-input-search
                v-model="voucherCode"
                placeholder="Mã khuyến mại"
                enter-button
                @search="onSearchVoucher"
              />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="14"></a-col>
      </a-row>
      <div class="d-loading-indicator" v-if="loading" :style="{ width: $mq === 'mobile' ? '100%' : '50%' }">
        <a-spin />
      </div>
      <div class="d-body-container" v-else>
        <a-row>
          <a-col :span="24">
            <div class="section" v-if="!!listUsageDetail">
              <div class="section-title">
                Lịch sử sử dụng code
              </div>
              <a-table
                :columns="columns"
                :data-source="listUsageDetail"
                :locale="{
                  emptyText: 'Không có dữ liệu'
                }"
                :pagination="{ defaultPageSize: 20 }"
              >
                <div slot="endDate" slot-scope="text">{{ text | formatDate }}</div>
                <div slot="completeBillTime" slot-scope="text">{{ text | formatDate }}</div>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-card>
</template>

<script>
import { mixin, mixinDevice } from '@/utils/mixin'
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CampaignRepo = RepositoryFactory.get('campaignRepository')

const columns = [
  {
    title: 'Id chiến dịch',
    dataIndex: 'campaignId',
    key: 'campaignId'
  },
  {
    title: 'Tên chiến dịch',
    dataIndex: 'campaignName',
    key: 'campaignName'
  },
  {
    title: 'Ngày kết thúc',
    dataIndex: 'campaignEndDate',
    key: 'campaignEndDate',
    scopedSlots: { customRender: 'endDate' }
  },
  {
    title: 'Mã khuyến mãi',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Tổng số lượng sử dụng',
    dataIndex: 'totalUsed',
    key: 'totalUsed'
  },
  {
    title: 'Lượt giới hạn sử dụng code',
    dataIndex: 'maxUsage',
    key: 'maxUsage'
  },
  {
    title: 'Salon đã dùng code',
    dataIndex: 'salonShortName',
    key: 'salonShortName'
  },
  {
    title: 'Ngày dùng code',
    dataIndex: 'completeBillTime',
    key: 'completeBillTime',
    scopedSlots: { customRender: 'completeBillTime' }
  }
]

export default {
  name: 'VoucherUsageDetail',
  mixins: [mixin, mixinDevice],
  data() {
    return {
      baseInfo: {},
      loading: false,
      columns,
      voucherCode: '',
      listUsageDetail: []
    }
  },
  methods: {
    async fetchCampaignHistory(phone) {
      try {
        const data = await CampaignRepo.getCampaignHistoryByPhone(phone)

        this.campaigns = data.listCampaign
      } catch (error) {
        console.log('fetchCampaignHistory error', error)
        this.campaigns = []
      }
    },
    async onSearchVoucher() {
      try {
        const data = await CampaignRepo.getVoucherListUsageDetail(this.voucherCode)

        this.listUsageDetail = data
      } catch (error) {
        console.log('getVoucherListUsageDetail error', error)
      }
    }
  },
  created() {}
}
</script>

<style scoped>
.d-value {
  display: block;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.d-label {
  display: block;
  text-align: center;
}
.d-info-card {
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  margin-bottom: 10px;
  border-radius: 2px;
  height: 15;
  line-height: 15px;
  text-align: center;
  box-shadow: 0px 1px 2px;
}
.d-button {
  margin-right: 20px !important;
  display: inline;
}
.d-group-button {
  display: inline;
}
.d-all-button {
  /* color: white;
  background-color: #1890ff !important; */
}
.d-salon-button {
  border: 1px solid orange !important;
}
.d-online-button {
  border: 1px solid green !important;
}
.d-button-focus:focus {
  background-color: #d6eafc !important;
}
.d-body-container {
  margin-top: 30px;
}

.d-card {
  border: 1px solid #e8e8e8 !important;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 1px 2px;
  margin-top: 10px;
}

.d-title-card {
  font-weight: bold;
}
.d-subtitle-card {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 10px;
}
.d-service-label {
  font-weight: bold;
  margin: 5px;
}
.d-service-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  background-color: #f2f2f2;
}
.d-service-name {
}
.d-service-value {
  font-style: italic;
}
.d-service-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold !important;
  margin: 5px;
}
.d-button-view-detail {
  color: white;
  background-color: green !important;
  float: right;
}
.d-button-view-detail-product-return {
  color: white;
  background-color: black !important;
  float: right;
}
.d-card-bill-detail {
  margin: 10px;
}
.d-loading-indicator {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.d-group-customer-info {
  margin-bottom: 10px;
}

.d-group-radio {
  text-align: center;
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.d-pagination {
  text-align: center;
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

._img {
  display: flex;
}
.responsive {
  padding: 0 6px;
  width: 25%;
}

.customer-tags-container {
  border: 1px solid #333;
  padding: 5px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.section {
  padding-left: 10px;
}
</style>
